import { render, staticRenderFns } from "./CommentForm.vue?vue&type=template&id=24f0952b&scoped=true&"
import script from "./CommentForm.vue?vue&type=script&lang=js&"
export * from "./CommentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f0952b",
  null
  
)

export default component.exports